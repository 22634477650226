<script setup>


const showMobileMenu = ref(false);
provide("showMobileMenu", showMobileMenu);

const onOpenMobileMenu = () => {
  showMobileMenu.value = true;
};
</script>

<template>
  <div class="min-h-screen dark:bg-gray-900 pb-10 flex flex-col">
    <MobileMenu />
    <MainHeader @open-mobile-menu="onOpenMobileMenu" />
    <div class="flex-1">
      <slot />
    </div>
    <CookieSection/>
    <client-only>

      <MainFooter />
    </client-only>
  </div>
</template>
